import { Builder, withChildren } from '@builder.io/react'
import { BaseModal } from './BaseModal'

const BaseModalWithChildren = withChildren(BaseModal)

Builder.registerComponent(BaseModalWithChildren, {
  name: 'Custom Launch modal',
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'I am child text block!' } },
    },
  ],
  inputs: [
    {
      name: 'buttonText',
      type: 'string',
      defaultValue: 'Launch modal',
    },
    {
      name: 'modalWidth',
      type: 'string',
      enum: ['sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl', '7xl', '8xl'],
      defaultValue: 'md',
    },
    {
      name: 'buttonType',
      type: 'string',
      enum: ['button', 'underlinedLink'],
      defaultValue: 'button',
    },
  ],
})
