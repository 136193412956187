import NextLink, { LinkProps as NextLinkProps } from "next/link"
interface CustomLinkProps extends NextLinkProps {
  children: React.ReactNode
}

const Link: React.FC<CustomLinkProps> = ({ href, children, ...props }) => {
  return (
    <NextLink href={href} {...props}>
      {children}
    </NextLink>
  )
}

export default Link
