import { z } from "zod"
import validator from "validator"

const requiredString = z.string().min(1, { message: "Required" })

export const SubscribeToList = z.object({
  fullName: requiredString,
  email: z.string().email(),
  phone: requiredString,
  message: z.string().optional(),
  listId: z.string(),
})

export const SubscribeToListApi = SubscribeToList.partial()
  .refine((data) => data.email || data.phone, "Either email or phone is required")
  .refine((data) => data.listId, "list ID is required")

export const SubscribeToFoundersClubForm = SubscribeToList.omit({ listId: true })
export const SubscribeToVanCaveForm = SubscribeToList.pick({ email: true })

export const ConfiguratorEstimateForm = z.object({
  fullName: requiredString,
  email: z.string().email({ message: "Invalid Email" }),
  phone: z.string().refine(validator.isMobilePhone).optional().or(z.literal("")),
})
