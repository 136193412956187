import { Builder } from '@builder.io/react'
import { PricingSectionsWithComparisonTable } from './PricingSectionsWithComparisonTable'
const defaultTiers = [
  {
    name: 'Backwoods',
    href: '#',
    price: '$40,000',
    description: 'Quis suspendisse ut fermentum neque vivamus non tellus.',
  },
  {
    name: 'Evergreen',
    href: '#',
    price: '$50,000',
    description: 'Quis suspendisse ut fermentum neque vivamus non tellus.',
  },
  {
    name: 'Nomadic',
    href: '#',
    price: '$60,000',
    description: 'Quis suspendisse ut fermentum neque vivamus non tellus.',
  },
]

Builder.registerComponent(PricingSectionsWithComparisonTable, {
  name: 'Comparison table',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd3fad4746b794e59a7bc6ba502ec4f44',
  inputs: [
    {
      name: 'tiers',
      type: 'list',
      subFields: [
        {
          name: 'name',
          type: 'string',
          defaultValue: 'Basic',
        },
        {
          name: 'href',
          type: 'string',
          defaultValue: '#',
        },
        {
          name: 'buttonText',
          type: 'string',
          defaultValue: 'Buy now',
        },
        {
          name: 'price',
          type: 'string',
          defaultValue: '$60,000',
        },
        {
          name: 'description',
          type: 'string',
          defaultValue: 'Quis suspendisse ut fermentum neque vivamus non tellus.',
        },
      ],
      defaultValue: defaultTiers,
    },
    {
      name: 'sections',
      type: 'list',
      subFields: [
        {
          name: 'name',
          type: 'string',
          defaultValue: 'Features',
        },
        {
          name: 'features',
          type: 'list',
          subFields: [
            {
              name: 'name',
              type: 'string',
              defaultValue: 'Molestie lobortis massa.',
            },
            {
              name: 'tiers',
              type: 'list',
              helperText: 'Create a tier entry here for each tier you want an entry for.',
              subFields: [
                {
                  name: 'name',
                  type: 'string',
                  helperText:
                    'This value must equal the tier name \ni.e. to add an entry to the table for the Backwoods tier for this feature enter Backwoods here',
                },
                {
                  name: 'text',
                  type: 'string',
                  helperText: 'If this field is left blank a checkmark icon will be shown',
                },
              ],
            },
          ],
        },
      ],
      defaultValue: [
        {
          name: 'Features',
          features: [
            {
              name: 'Molestie lobortis massa.',
              tiers: [{ name: 'Backwoods' }, { name: 'Evergreen' }, { name: 'Nomadic' }],
            },
            {
              name: 'Urna purus felis.',
              tiers: [{ name: 'Backwoods' }, { name: 'Evergreen' }, { name: 'Nomadic' }],
            },
            {
              name: 'Tellus pulvinar sit dictum.',
              tiers: [{ name: 'Evergreen' }, { name: 'Nomadic' }],
            },
            {
              name: 'Convallis',
              tiers: [
                { name: 'Evergreen', text: 'Up to 20 users' },
                { name: 'Nomadic', text: 'Up to 50 users' },
              ],
            },
          ],
        },
      ],
    },
  ],
})
