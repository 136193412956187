/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/24/outline"
import { classNames } from "core/utils"
export function PricingSectionsThreeTiers(props) {
  const { sectionHeader, sectionDescription, tiers } = props
  return (
    <div className="mx-auto max-w-7xl bg-white py-24 px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">
        {sectionHeader}
      </h2>
      <p className="mt-6 max-w-2xl text-xl text-gray-500">{sectionDescription}</p>

      {/* Tiers */}
      <div className="mt-24 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
        {tiers.map((tier) => (
          <div
            key={tier.title}
            className="relative flex flex-col rounded-2xl border border-gray-200 bg-white p-8 shadow-sm"
          >
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-gray-900">{tier.title}</h3>
              {tier.mostPopular ? (
                <p className="absolute top-0 -translate-y-1/2 transform rounded-full bg-primary-500 py-1.5 px-4 text-xs font-semibold uppercase tracking-wide text-white">
                  Most popular
                </p>
              ) : null}
              <p className="mt-4 flex items-baseline text-gray-900">
                <span className="text-5xl font-extrabold tracking-tight">{tier.price}</span>
                <span className="ml-1 text-xl font-semibold">{tier.frequency}</span>
              </p>
              <p className="mt-6 text-gray-500">{tier.description}</p>

              {/* Feature list */}
              <ul role="list" className="mt-6 space-y-6">
                {tier.features.map((feature) => (
                  <li key={feature.name} className="flex">
                    <CheckIcon
                      className="text-primary-500 h-6 w-6 flex-shrink-0"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-gray-500">{feature.name}</span>
                  </li>
                ))}
              </ul>
            </div>

            <a
              href="#"
              className={classNames(
                tier.mostPopular
                  ? "bg-primary-500 hover:bg-primary-600"
                  : "text-primary-700 bg-primary-50 hover:bg-primary-100",
                "mt-8 block w-full rounded-md border border-transparent py-3 px-6 text-center font-medium text-white"
              )}
            >
              {tier.buttonText}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}
