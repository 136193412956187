import { OptionInput } from "../types"
import { useContext } from "react"
import { Context } from "../Context"

export function useAddItemToCart() {
  const { cart, setCart } = useContext(Context)

  async function addItemToCart(product_id: string, quantity: number, options?: OptionInput[]) {
    const response = await fetch("/api/cart/add", {
      method: "POST",
      body: JSON.stringify({
        cart_id: localStorage.getItem("cartId"),
        product_id,
        quantity,
        options,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      throw new Error("Failed to add item to cart")
    }

    const newCart = await response.json()
    localStorage.setItem("cartId", newCart.id)
    setCart(newCart)
    return newCart
  }

  return addItemToCart
}
