import { Builder } from '@builder.io/react'
import { MarketingSectionsWithBackgroundImageAndOverlappingCards } from './MarketingSectionsWithBackgroundImageAndOverlappingCards'

Builder.registerComponent(MarketingSectionsWithBackgroundImageAndOverlappingCards, {
  name: 'Marketing sections w/ bg image + overlapping cards',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd3fad4746b794e59a7bc6ba502ec4f44',
  inputs: [
    {
      name: 'sectionTitle',
      type: 'string',
      defaultValue: 'Support',
    },
    {
      name: 'buttonText',
      type: 'string',
      defaultValue: 'Contact Us',
    },
    {
      name: 'sectionDescription',
      type: 'string',
      defaultValue:
        'Organize is a Varius facilisi mauris sed sit. Non sed et duis dui leo, vulputate id malesuada non. Cras aliquet purus dui laoreet diam sed lacus, fames. Dui, amet, nec sit pulvinar. system to keep your desk tidy and photo- worthy all day long.Procrastinate your work while you meticulously arrange items into dedicated trays.',
    },
    {
      name: 'imageSrc',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue:
        'https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100',
    },
    {
      name: 'imageAlt',
      type: 'string',
      defaultValue: 'People working',
    },
    {
      name: 'supportLinks',
      type: 'list',
      subFields: [
        { name: 'name', type: 'string', defaultValue: 'Sales' },
        { name: 'href', type: 'string', defaultValue: '#' },
        {
          name: 'description',
          type: 'string',
          defaultValue:
            'Varius facilisi mauris sed sit. Non sed et duis dui leo, vulputate id malesuada non. Cras aliquet purus dui laoreet diam sed lacus, fames.Sales',
        },
        { name: 'icon', type: 'string', defaultValue: 'Sales' },
      ],
    },
    {
      name: 'tabs',
      type: 'list',
      subFields: [
        { name: 'name', type: 'string', defaultValue: 'Design' },
        {
          name: 'features',
          type: 'list',
          subFields: [
            { name: 'name', type: 'string', defaultValue: 'Adaptive and modular' },
            {
              name: 'description',
              type: 'string',
              defaultValue:
                'The Organize base set allows you to configure and evolve your setup as your items and habits change. The included trays and optional add-ons are easily rearranged to achieve that perfect setup.',
            },
            {
              name: 'imageSrc',
              type: 'file',
              allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
              required: true,
              defaultValue:
                'https://tailwindui.com/img/ecommerce-images/product-feature-06-detail-01.jpg',
            },
            {
              name: 'imageAlt',
              type: 'string',
              defaultValue:
                'Maple organizer base with slots, supporting white polycarbonate trays of various sizes.',
            },
          ],
        },
        { name: 'featureName', type: 'string', defaultValue: 'Dynamic Design' },
        {
          name: 'featureDescription',
          type: 'string',
          defaultValue:
            'Whether you are full-time on the road or a weekend warrior we understand that everything in the van must serve multiple purposes simultaneously that is why optionality is the design and engineering foundation of New Way Van Life builds and products.',
        },
      ],
    },
  ],
})
