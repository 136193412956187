/* This example requires Tailwind CSS v2.0+ */
import Image from "next/legacy/image"

interface SimpleCenteredProps {
  quote: string
  imageUrl: string
  personImageUrl: string
  personName: string
  personRole: string
}
export function SimpleCentered({
  quote,
  imageUrl,
  personImageUrl,
  personName,
  personRole,
}: SimpleCenteredProps) {
  return (
    <section className="overflow-hidden bg-gray-50 py-12 md:py-20 lg:py-24">
      <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <svg
          className="absolute right-full top-full -translate-y-1/4 translate-x-1/3 transform lg:translate-x-1/2 xl:-translate-y-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          role="img"
          aria-labelledby="svg-workcation"
        >
          <title id="svg-workcation">yo</title>
          <defs>
            <pattern
              id="ad119f34-7694-4c31-947f-5c9d249b21f3"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect width={404} height={404} fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)" />
        </svg>

        <div className="relative">
          <div className="relative">
            <div className="mx-auto h-8">
              <Image src={imageUrl} alt="Workcation" objectFit="contain" />
            </div>
          </div>

          <blockquote className="mt-10">
            <div className="mx-auto max-w-3xl text-center text-2xl font-medium leading-9 text-gray-900">
              <p>&ldquo;{quote}&rdquo;</p>
            </div>
            <footer className="mt-8">
              <div className="md:flex md:items-center md:justify-center">
                <div className="relative md:flex-shrink-0">
                  <div className="relative mx-auto h-10 w-10">
                    <Image
                      src={personImageUrl}
                      objectFit="fill"
                      alt={personName}
                      className="rounded-full"
                    />
                  </div>
                </div>
                <div className="mt-3 text-center md:ml-4 md:mt-0 md:flex md:items-center">
                  <div className="text-base font-medium text-gray-900">{personName}</div>

                  <svg
                    className="text-primary-600 mx-1 hidden h-5 w-5 md:block"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>

                  <div className="text-base font-medium text-gray-500">{personRole}</div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  )
}
