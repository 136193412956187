/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/24/outline"
import Link from "next/link"

import { useState, useRef, Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"

const Button = ({ buttonText, buttonType, buttonHref, children, modalWidth }) => {
  const [open, setOpen] = useState(false)
  const initialFocusEl = useRef(null)

  if (buttonType === "modal") {
    return (
      <>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            onClose={setOpen}
            initialFocus={initialFocusEl}
          >
            <div className="flex min-h-screen items-end justify-center px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div
                  className={`inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-${modalWidth} w-full`}
                >
                  <div ref={initialFocusEl}>{children}</div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <button
          type="button"
          className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-primary-600 px-5 py-3 text-base font-medium text-white hover:bg-primary-700 sm:mt-10 sm:w-auto xl:mt-0"
          onClick={() => setOpen(true)}
        >
          {buttonText}
        </button>
      </>
    )
  } else if (buttonType === "link") {
    return (
      <Link
        href={buttonHref}
        className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-primary-600 px-5 py-3 text-base font-medium text-white hover:bg-primary-700 sm:mt-10 sm:w-auto xl:mt-0"
      >
        {buttonText}
      </Link>
    )
  } else return <></>
}
export function PricingSectionsSingleWithFeatures(props) {
  const {
    sectionHeader,
    sectionHeaderPrimaryColor,
    sectionDescription,
    buttonText,
    buttonType,
    buttonHref,
    featuresHeader,
    featuresSecondHeader,
    featuresDescription,
    features,
    modalWidth,
    children,
  } = props
  return (
    <>
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <div className="pb-16 xl:flex xl:items-center xl:justify-between">
          <div>
            <h1 className="text-4xl font-extrabold sm:text-5xl sm:tracking-tight">
              <span className="text-gray-900">{sectionHeader} </span>
              <span className="text-primary-600">{sectionHeaderPrimaryColor}</span>
            </h1>
            <p className="mt-5 text-xl text-gray-500">{sectionDescription}</p>
          </div>
          <Button
            buttonText={buttonText}
            buttonType={buttonType}
            buttonHref={buttonHref}
            modalWidth={modalWidth}
          >
            {children}
          </Button>
        </div>
        <div className="border-t border-gray-200 pt-16 xl:grid xl:grid-cols-3 xl:gap-x-8">
          <div>
            <h2 className="text-primary-600 text-base font-semibold uppercase tracking-wide">
              {featuresHeader}
            </h2>
            <p className="mt-2 text-3xl font-extrabold text-gray-900">{featuresSecondHeader}</p>
            <p className="mt-4 text-lg text-gray-500">{featuresDescription}</p>
          </div>
          <div className="mt-4 sm:mt-8 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:col-span-2 xl:mt-0">
            <ul role="list" className="divide-y divide-gray-200">
              {features.slice(0, 5).map((feature, featureIdx) =>
                featureIdx === 0 ? (
                  <li key={feature.name} className="flex py-4 md:py-0 md:pb-4">
                    <CheckIcon
                      className="h-6 w-6 flex-shrink-0 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-base text-gray-500">{feature.name}</span>
                  </li>
                ) : (
                  <li key={feature.name} className="flex py-4">
                    <CheckIcon
                      className="h-6 w-6 flex-shrink-0 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-base text-gray-500">{feature.name}</span>
                  </li>
                )
              )}
            </ul>
            <ul
              role="list"
              className="divide-y divide-gray-200 border-t border-gray-200 md:border-t-0"
            >
              {features.slice(5).map((feature, featureIdx) =>
                featureIdx === 0 ? (
                  <li key={feature.name} className="flex py-4 md:border-t-0 md:py-0 md:pb-4">
                    <CheckIcon
                      className="h-6 w-6 flex-shrink-0 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-base text-gray-500">{feature.name}</span>
                  </li>
                ) : (
                  <li key={feature.name} className="flex py-4">
                    <CheckIcon
                      className="h-6 w-6 flex-shrink-0 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-base text-gray-500">{feature.name}</span>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
