import { Builder } from '@builder.io/react'
import { SimpleCentered } from './SimpleCentered'

Builder.registerComponent(SimpleCentered, {
  name: 'simple centered - testimonials',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd3fad4746b794e59a7bc6ba502ec4f44',
  inputs: [
    {
      name: 'quote',
      type: 'longText',
      defaultValue:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo expedita voluptas culpa sapiente alias molestiae. Numquam corrupti in laborum sed rerum et corporis.',
    },
    {
      name: 'imageUrl',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      defaultValue:
        'https://tailwindui.com/img/logos/workcation-logo-indigo-600-mark-gray-800-and-indigo-600-text.svg',
    },
    {
      name: 'personName',
      type: 'string',
      defaultValue: 'Judith Black',
    },
    {
      name: 'personRole',
      type: 'string',
      defaultValue: 'CEO, Workcation',
    },
    {
      name: 'personImageUrl',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      defaultValue:
        'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  ],
})
