import { Builder } from '@builder.io/react';
import { ProductFeaturesWithTwoImages } from './ProductFeaturesWithTwoImages';

Builder.registerComponent(ProductFeaturesWithTwoImages, {
  name: 'Product Features w/ header, two images, and description',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd3fad4746b794e59a7bc6ba502ec4f44',
  inputs: [
    {
      name: 'sectionTitle',
      type: 'string',
      defaultValue: 'Your Title Here',
    },
    {
      name: 'sectionDescription',
      type: 'string',
      defaultValue: 'Your cool description here.',
    },
    {
      name: 'text1',
      type: 'string',
      defaultValue: 'Your Title Here',
    },
    {
      name: 'image1',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue:
        'https://tailwindui.com/img/ecommerce-images/product-page-04-detail-product-shot-01.jpg',
    },
    {
      name: 'text2',
      type: 'string',
      defaultValue: 'Your Title Here',
    },
    {
      name: 'image2',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue:
        'https://tailwindui.com/img/ecommerce-images/product-page-04-detail-product-shot-02.jpg',
    },
  ],
});
