import { useContext } from "react"
import { Context } from "../Context"

import { useGetLineItem } from "./useGetLineItem"

export function useUpdateItemQuantity() {
  const { swell, setCart } = useContext(Context)
  const getLineItem = useGetLineItem()

  async function updateItemQuantity(itemId: string | number, quantity: number) {
    if (itemId == null) {
      throw new Error("Must provide an item id")
    }

    if (quantity == null || Number(quantity) < 0) {
      throw new Error("Quantity must be greater than 0")
    }

    const lineItem = getLineItem(itemId)

    if (lineItem == null) {
      throw new Error(`Item with id ${itemId} not in cart`)
    }
    let newCart
    if (quantity == 0 || Number(quantity) == 0) {
      const response = await fetch("/api/cart/remove", {
        method: "POST",
        body: JSON.stringify({
          cartId: localStorage.getItem("cartId"),
          itemId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (!response.ok) {
        throw new Error("Failed to remove item from cart")
      }

      newCart = await response.json()
    } else {
      const response = await fetch("/api/cart/updateItem", {
        method: "POST",
        body: JSON.stringify({
          cartId: localStorage.getItem("cartId"),
          itemId,
          quantity,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })

      if (!response.ok) {
        throw new Error("Failed to remove item from cart")
      }

      newCart = await response.json()
    }
    setCart(newCart)
  }

  return updateItemQuantity
}
