import React from "react"
import { Form, FormProps } from "@components/common/Form"
import { ZodType } from "zod"
import { useFormikContext, useField } from "formik"
export { FORM_ERROR, FORM_SUCCESS } from "@components/common/Form"

export function VanCaveForm<S extends ZodType<any, any>>(props: FormProps<S>) {
  const Fields = () => {
    const { isSubmitting } = useFormikContext()
    const [input] = useField("email")

    return (
      <>
        <label htmlFor="email-address" className="sr-only">
          Email address
        </label>
        <input
          {...input}
          disabled={isSubmitting}
          type="email"
          autoComplete="email"
          className="w-full px-5 py-3 border border-transparent placeholder-gray-500 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white sm:max-w-xs rounded-md"
          placeholder="Email"
        />

        <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
          <button
            type="submit"
            className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-primary-500"
            disabled={isSubmitting}
          >
            {props.alternateSubmitText}
          </button>
        </div>
      </>
    )
  }

  return (
    <Form<S> className="sm:flex" {...props}>
      <Fields />
    </Form>
  )
}
