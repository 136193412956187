import { Builder } from '@builder.io/react'
import { PromoFullWidthWithBackgroundImage } from './PromoFullWidthWithBackgroundImage'

Builder.registerComponent(PromoFullWidthWithBackgroundImage, {
  name: 'Full-width with background image',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd3fad4746b794e59a7bc6ba502ec4f44',
  inputs: [
    {
      name: 'sectionTitle',
      type: 'string',
      defaultValue: 'Long-term thinking',
    },
    {
      name: 'sectionDescription',
      type: 'string',
      defaultValue: `We're committed to responsible, sustainable, and ethical manufacturing. Our small-scale approach allows us to focus on quality and reduce our impact. We're doing our best to delay the inevitable heat-death of the universe.`,
    },
    {
      name: 'linkText',
      type: 'string',
      defaultValue: `Read our story`,
    },
    {
      name: 'backgroundImageSrc',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue:
        'https://tailwindui.com/img/ecommerce-images/home-page-03-feature-section-full-width.jpg',
    },
  ],
})
