import { Builder } from '@builder.io/react'
import { CenteredAccordionWithTitle } from './CenteredAccordionWithTitle'

Builder.registerComponent(CenteredAccordionWithTitle, {
  name: 'Centered Accordion',
  image: 'https://tailwindui.com/img/components/faq-sections.01-centered-accordion-xl.png',
  inputs: [
    {
      name: 'sectionTitle',
      type: 'string',
      defaultValue: 'Frequently asked questions',
    },
    {
      name: 'items',
      type: 'list',
      subFields: [
        { name: 'title', type: 'string', defaultValue: "What's the best thing about Switzerland?" },
        {
          name: 'text',
          type: 'string',
          defaultValue:
            "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
        },
      ],
    },
  ],
})
