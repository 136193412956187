/* This example requires Tailwind CSS v2.0+ */
import { TruckIcon } from "@heroicons/react/24/outline"
import { MuxVideoPlayer } from "@components/MuxVideoPlayer/MuxVideoPlayer"
type StatType = {
  value: string
  name: string
}

type MuxVideo = {
  playbackId: string
  gifStart: string
  gifEnd: string
  imageAt: string
}
interface SplitWithImageProps {
  sectionTitle: string
  sectionDescription: string
  stats: StatType[]
  muxVideo: MuxVideo
}
export function SplitWithImage({
  sectionTitle,
  sectionDescription,
  stats,
  muxVideo,
}: SplitWithImageProps) {
  return (
    <div className="relative bg-white">
      <div className="z-10 bg-primary-600 sm:flex  sm:items-center lg:absolute lg:left-0 lg:h-full lg:w-1/2">
        <MuxVideoPlayer
          playbackId={muxVideo.playbackId}
          gifStart={muxVideo.gifStart}
          gifEnd={muxVideo.gifEnd}
          imageAt={muxVideo.imageAt}
        />
      </div>
      <div className="relative mx-auto max-w-7xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16">
        <div className="mx-auto max-w-2xl lg:mr-0 lg:ml-auto lg:w-1/2 lg:max-w-none lg:pl-10">
          <div>
            <div className="flex h-12 w-12 items-center justify-center rounded-md bg-primary-500 text-white">
              <TruckIcon className="h-6 w-6" aria-hidden="true" />
            </div>
          </div>
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900 sm:text-4xl">{sectionTitle}</h2>
          <p className="mt-6 text-lg text-gray-500">{sectionDescription}</p>
          <div className="mt-8 overflow-hidden">
            <dl className="-mx-8 -mt-8 flex flex-wrap">
              {stats.map((stat, index) => {
                return (
                  <div className="flex flex-col px-8 pt-8" key={index}>
                    <dt className="order-2 text-base font-medium text-gray-500">{stat.name}</dt>
                    <dd className="text-primary-600 order-1 text-2xl font-extrabold sm:text-3xl">
                      {stat.value}
                    </dd>
                  </div>
                )
              })}
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}
