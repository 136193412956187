import { Builder, withChildren } from '@builder.io/react'
import { PricingSectionsSingleWithFeatures } from './PricingSectionsSingleWithFeatures'

const PricingSectionsSingleWithFeaturesWithChildren = withChildren(
  PricingSectionsSingleWithFeatures
)

Builder.registerComponent(PricingSectionsSingleWithFeaturesWithChildren, {
  name: 'Single price w/ feature list',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd3fad4746b794e59a7bc6ba502ec4f44',
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'I am child text block!' } },
    },
  ],
  inputs: [
    {
      name: 'sectionHeader',
      type: 'string',
      defaultValue: 'Everything you need for',
    },
    {
      name: 'sectionHeaderPrimaryColor',
      type: 'string',
      defaultValue: '$99 a month',
    },
    {
      name: 'sectionDescription',
      type: 'string',
      defaultValue: 'Includes every feature we offer plus unlimited projects and unlimited users.',
    },
    {
      name: 'buttonText',
      type: 'string',
      defaultValue: 'Get started today',
    },
    {
      name: 'buttonType',
      type: 'string',
      enum: ['modal', 'link'],
      defaultValue: 'link',
    },
    {
      name: 'buttonHref',
      type: 'string',
      defaultValue: '#',
      helperText: 'for link button type',
    },
    {
      name: 'modalWidth',
      type: 'string',
      enum: ['sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl', '7xl', '8xl'],
      defaultValue: 'md',
      helperText: 'for modal button type',
    },
    {
      name: 'featuresHeader',
      type: 'string',
      defaultValue: 'Everything you need',
    },
    {
      name: 'featuresSecondHeader',
      type: 'string',
      defaultValue: 'All-in-one platform',
    },
    {
      name: 'featuresDescription',
      type: 'string',
      defaultValue:
        'Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla nec. Urna, sed a lectus elementum blandit et.',
    },
    {
      name: 'features',
      type: 'list',
      subFields: [
        {
          name: 'name',
          type: 'string',
          defaultValue: 'Vitae in pulvinar odio id utobortis in inter.',
        },
      ],
      defaultValue: [
        {
          name: 'Vitae in pulvinar odio id utobortis in inter.',
        },
        {
          name: 'Sed sed id viverra viverra augue eget massa.',
        },
        {
          name: 'Urna, gravida amet, a, integer venenatis.',
        },
        {
          name: 'Lobortis sed pharetra amet vitae eleifend.',
        },
        {
          name: 'Ullamcorper blandit a consequat donec elit aoreet.',
        },
        {
          name: 'Dolor quo assumenda.',
        },
        {
          name: 'Esse rerum distinctio maiores maiores.',
        },
        {
          name: 'Eos enim officiis ratione.',
        },
        {
          name: 'Tempore molestiae aliquid excepturi.',
        },
        {
          name: 'Perspiciatis eveniet inventore eum et aliquam.',
        },
      ],
    },
  ],
})
