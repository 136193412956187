import Image from "next/image"

export function PromoFullWidthWithBackgroundImage(props) {
  const { sectionTitle, sectionDescription, backgroundImageSrc, linkText } = props
  return (
    <div className="relative bg-gray-800 px-6 py-32 sm:px-12 sm:py-40 lg:px-16">
      <div className="absolute inset-0 overflow-hidden">
        <Image
          src={backgroundImageSrc}
          alt=""
          className="h-full w-full object-cover object-center"
          fill={true}
        />
      </div>
      <div aria-hidden="true" className="absolute inset-0 bg-gray-900 bg-opacity-50" />
      <div className="relative mx-auto flex max-w-3xl flex-col items-center text-center">
        <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
          {sectionTitle}
        </h2>
        <p className="mt-3 text-xl text-white">{sectionDescription}</p>
        <a
          href="#"
          className="mt-8 block w-full rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-gray-900 hover:bg-gray-100 sm:w-auto"
        >
          {linkText}
        </a>
      </div>
    </div>
  )
}
