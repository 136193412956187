import { Builder } from '@builder.io/react'
import { IncentiveThreeColumnWithSplitHeader } from './IncentiveThreeColumnWithSplitHeader'

Builder.registerComponent(IncentiveThreeColumnWithSplitHeader, {
  name: '3-column with illustrations and split header',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd3fad4746b794e59a7bc6ba502ec4f44',
  inputs: [
    {
      name: 'sectionTitle',
      type: 'string',
      defaultValue: 'We built our business on great customer service',
    },
    {
      name: 'sectionDescription',
      type: 'string',
      defaultValue: `At the beginning at least, but then we realized we could make a lot more money if we kinda stopped caring about that. Our new strategy is to write a bunch of things that look really good in the headlines, then clarify in the small print but hope people don't actually read it.`,
    },
    {
      name: 'imageSrc',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue: 'https://tailwindui.com/img/ecommerce-images/incentives-07-hero.jpg',
    },
    { name: 'imageAlt', type: 'string', required: true, defaultValue: 'clean desk' },
    {
      name: 'incentives',
      type: 'list',
      subFields: [
        { name: 'name', type: 'string', defaultValue: 'Origin' },
        { name: 'description', type: 'string', defaultValue: 'Designed by Good Goods, Inc.' },
        {
          name: 'imageSrc',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
          defaultValue: 'https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg',
        },
      ],
    },
  ],
})
