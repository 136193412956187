import { Builder, withBuilder } from '@builder.io/react'
import { ProductFeaturesWithTabs } from './ProductFeaturesWithTabs'
const defaultElementOne = {
  '@type': '@builder.io/sdk:Element',
  responsiveStyles: {
    large: {
      height: '200px',
      display: 'flex',
      marginTop: '20px',
      flexDirection: 'column',
    },
  },
  component: {
    name: 'Text',
    options: {
      text: 'New tab content ',
    },
  },
}

const defaultElementTwo = {
  '@type': '@builder.io/sdk:Element',
  '@version': 2,
  id: 'builder-1a8c220e00cd4b43b8ef269ef10e01d1',
  component: {
    name: 'Product Features w/ image',
    options: {
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-feature-06-detail-01.jpg',
      imageAlt:
        'Maple organizer base with slots, supporting white polycarbonate trays of various sizes.',
      features: [
        {
          name: 'Adaptive and modular',
          description:
            'The Organize base set allows you to configure and evolve your setup as your items and habits change. The included trays and optional add-ons are easily rearranged to achieve that perfect setup.',
        },
        {
          name: 'Adaptive and modular',
          description:
            'The Organize base set allows you to configure and evolve your setup as your items and habits change. The included trays and optional add-ons are easily rearranged to achieve that perfect setup.',
        },
        {
          name: 'Adaptive and modular',
          description:
            'The Organize base set allows you to configure and evolve your setup as your items and habits change. The included trays and optional add-ons are easily rearranged to achieve that perfect setup.',
        },
        {
          name: 'Adaptive and modular',
          description:
            'The Organize base set allows you to configure and evolve your setup as your items and habits change. The included trays and optional add-ons are easily rearranged to achieve that perfect setup.',
        },
      ],
    },
  },
  responsiveStyles: {
    large: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      flexShrink: '0',
      boxSizing: 'border-box',
    },
  },
}

Builder.registerComponent(ProductFeaturesWithTabs, {
  name: 'Product Features w/ tabs and features',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd3fad4746b794e59a7bc6ba502ec4f44',
  inputs: [
    {
      name: 'sectionTitle',
      type: 'string',
      defaultValue: 'Technical Specifications',
    },
    {
      name: 'sectionDescription',
      type: 'string',
      defaultValue:
        'Organize is a system to keep your desk tidy and photo-worthy all day long. Procrastinate your work while you meticulously arrange items into dedicated trays.',
    },
    {
      name: 'centered',
      type: 'boolean',
      defaultValue: false,
    },
    {
      name: 'tabs',
      type: 'list',
      subFields: [
        { name: 'name', type: 'string', defaultValue: 'Design' },
        {
          name: 'features',
          type: 'uiBlocks',
          hideFromUI: true,
          defaultValue: [defaultElementOne],
        },
      ],
      defaultValue: [
        {
          name: 'Design',
          content: [
            {
              ...defaultElementOne,
            },
          ],
        },
        {
          name: 'Materials',
          content: [
            {
              ...defaultElementTwo,
            },
          ],
        },
      ],
    },
  ],
})
