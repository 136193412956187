import { Builder, withChildren } from "@builder.io/react"
import { IntraPageNavBar } from "./IntraPageNavBar"

Builder.registerComponent(IntraPageNavBar, {
  name: "IntraPageNavBar",
  noWrap: true,
  inputs: [
    {
      name: "sections",
      type: "list",
      subFields: [
        {
          name: "id",
          type: "string",
          required: true,
          helperText: "Note: ID must already exist on the target html element",
        },
        {
          name: "title",
          type: "string",
          required: true,
        },
      ],
    },
  ],
})
