import Image from "next/image"

export function ProductFeaturesWithImage(props) {
  const { features, imageSrc, imageAlt } = props
  return (
    <div className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-x-8">
      <div className="mt-6 space-y-4 lg:col-span-5 lg:mt-0">
        {features &&
          features.map((feature, idx) => (
            <div key={idx}>
              <h3 className="text-lg font-medium text-gray-900">{feature.name}</h3>
              <p className="mt-2 text-sm text-gray-500">{feature.description}</p>
            </div>
          ))}
      </div>
      <div className="lg:col-span-7">
        <div className="aspect-h-1 aspect-w-2 overflow-hidden rounded-lg bg-gray-100 sm:aspect-h-2 sm:aspect-w-5">
          <Image src={imageSrc} alt={imageAlt} className="object-cover object-center" fill={true} />
        </div>
      </div>
    </div>
  )
}
