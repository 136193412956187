import { Builder } from '@builder.io/react'
import { ProductFeaturesWithFadingImage } from './ProductFeaturesWithFadingImage'

Builder.registerComponent(ProductFeaturesWithFadingImage, {
  name: 'Product Features w/ fading image and specs',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd3fad4746b794e59a7bc6ba502ec4f44',
  inputs: [
    {
      name: 'sectionTitle',
      type: 'string',
      defaultValue: 'Technical Specifications',
    },
    {
      name: 'sectionDescription',
      type: 'string',
      defaultValue:
        'Organize is a system to keep your desk tidy and photo-worthy all day long. Procrastinate your work while you meticulously arrange items into dedicated trays.',
    },
    {
      name: 'features',
      type: 'list',
      subFields: [
        { name: 'name', type: 'string', defaultValue: 'Origin' },
        { name: 'description', type: 'string', defaultValue: 'Designed by Good Goods, Inc.' },
      ],
    },
    {
      name: 'imageSrc',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue: 'https://tailwindui.com/img/ecommerce-images/product-feature-02-full-width.jpg',
    },
    {
      name: 'imageAlt',
      type: 'string',
      defaultValue: 'Really cool image',
    },
  ],
})
