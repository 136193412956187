import React from "react"
import { Form, FormProps } from "@components/common/Form"
import { ZodType } from "zod"
import LabeledTextField from "@components/common/LabeledTextField"
import LabeledTextAreaField from "@components/common/LabeledTextAreaField"
export { FORM_ERROR, FORM_SUCCESS } from "@components/common/Form"

export function FoundersClubForm<S extends ZodType<any, any>>(props: FormProps<S>) {
  return (
    <Form<S> {...props}>
      <LabeledTextField
        type="text"
        name="fullName"
        label="Full name"
        placeholder="Full name"
        hiddenLabel={true}
      />
      <LabeledTextField
        type="email"
        name="email"
        label="Email"
        placeholder="Email"
        hiddenLabel={true}
      />
      <LabeledTextField
        type="text"
        name="phone"
        label="Phone"
        placeholder="Phone"
        hiddenLabel={true}
      />
      <LabeledTextAreaField
        name="message"
        label="Message"
        placeholder="Message"
        hiddenLabel={true}
      />
    </Form>
  )
}
