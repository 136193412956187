import { Builder } from '@builder.io/react'
import { PromoWithOfferAndSplitImage } from './PromoWithOfferAndSplitImage'

Builder.registerComponent(PromoWithOfferAndSplitImage, {
  name: 'With offers and split image',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd3fad4746b794e59a7bc6ba502ec4f44',
  inputs: [
    {
      name: 'sectionTitle',
      type: 'string',
      defaultValue: 'Focus on what matters',
    },
    {
      name: 'sectionDescription',
      type: 'string',
      defaultValue: `All the charts, datepickers, and notifications in the world can't beat checking off some items on a paper card.`,
    },
    {
      name: 'linkText',
      type: 'string',
      defaultValue: `Shop Productivity`,
    },
    {
      name: 'imageSrc',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue: 'https://tailwindui.com/img/ecommerce-images/home-page-02-hero-half-width.jpg',
    },
    {
      name: 'offers',
      type: 'list',
      subFields: [
        {
          name: 'name',
          type: 'string',
          defaultValue: 'Download the app',
        },
        { name: 'description', type: 'string', defaultValue: 'Get an exclusive $5 off code' },
        { name: 'href', type: 'string', defaultValue: '#' },
      ],
    },
  ],
})
