/** @jsxImportSource theme-ui */

import { Container, Heading, Box, Grid, Button } from "theme-ui"
import Image from "next/legacy/image"
import Link from "next/link"
import { Builder } from "@builder.io/react"
import { jsx } from "theme-ui"

export default function ProductCta(props: any) {
  return (
    <Container sx={{ color: `${props.color}` }}>
      <Grid
        gap={2}
        sx={{
          gridTemplateColumns:
            "[full-start] minmax(0px, auto) [main-start] repeat(16, 72px) [main-end] minmax(0px, auto) [full-end]",
          gridTemplateRows: "auto 1fr",
        }}
      >
        <Heading sx={{ fontSize: 6, gridColumn: "3/9", color: "primary" }}>{props.title}</Heading>
        <div sx={{ marginTop: 4, gridColumn: "3/9" }}>
          <Heading as="h3" sx={{ color: "secondary" }}>
            {props.subTitle}
          </Heading>
        </div>
        <div
          sx={{
            gridColumn: "3/14",
          }}
        >
          <div
            sx={{
              width: "100%",
              position: "relative",
              height: 500,
              marginTop: 4,
            }}
          >
            <Image src={props.image} alt="product" objectFit="cover" />
          </div>
          <Grid gap={2} columns={"repeat(2, 1fr)"} sx={{ width: 432, margin: "1.4rem 0px 9.6rem" }}>
            <Link href="#" passHref legacyBehavior>
              <Button variant="secondary" sx={{ bg: "secondary", color: "black" }}>
                Explore
              </Button>
            </Link>
            <Link href="#" passHref legacyBehavior>
              <Button variant="primary">Configure Now</Button>
            </Link>
          </Grid>
        </div>
      </Grid>
    </Container>
  )
}

Builder.registerComponent(ProductCta, {
  name: "ProductCTA",
  inputs: [
    {
      name: "title",
      type: "text",
      defaultValue: "A new way to adventure is here.",
    },
    {
      name: "subTitle",
      type: "text",
      defaultValue:
        "Now there’s a way to explore our beautiful planet for everyone. Learn more about our modular and rugged camper van conversion kit.",
    },
    {
      name: "color",
      type: "color",
      defaultValue: "black",
    },
    {
      name: "image",
      type: "file",
      allowedFileTypes: ["jpeg", "png", "gif", "svg"],
    },
  ],
})
