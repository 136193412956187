import Image from "next/image"

export function ProductFeaturesWithSquareImages(props) {
  const { sectionHeader, sectionTitle, sectionDescription, features } = props
  return (
    <>
      <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        {sectionTitle && (
          <div className="mb-11 max-w-3xl sm:mb-16">
            <h2 id="features-heading" className="font-medium text-gray-500">
              {sectionHeader}
            </h2>
            <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              {sectionTitle}
            </p>
            <p className="mt-4 text-gray-500">{sectionDescription}</p>
          </div>
        )}

        <div className="grid grid-cols-1 items-start gap-x-6 gap-y-16 sm:grid-cols-2 lg:grid-cols-4 lg:gap-x-8">
          {features &&
            features.map((feature) => (
              <div key={feature.name} className="flex flex-col-reverse">
                <div className="mt-6">
                  <h3 className="text-sm font-medium text-gray-900">{feature.name}</h3>
                  <p className="mt-2 text-sm text-gray-500">{feature.description}</p>
                </div>
                <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100">
                  <Image
                    fill={true}
                    src={feature.imageSrc}
                    alt={feature.imageAlt}
                    className="object-cover object-center"
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  )
}
