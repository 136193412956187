import { Builder } from '@builder.io/react'
import { ProductFeaturesWithImage } from './ProductFeaturesWithImage'

Builder.registerComponent(ProductFeaturesWithImage, {
  name: 'Product Features w/ image',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd3fad4746b794e59a7bc6ba502ec4f44',
  inputs: [
    {
      name: 'features',
      type: 'list',
      subFields: [
        { name: 'name', type: 'string', defaultValue: 'Adaptive and modular' },
        {
          name: 'description',
          type: 'string',
          defaultValue:
            'The Organize base set allows you to configure and evolve your setup as your items and habits change. The included trays and optional add-ons are easily rearranged to achieve that perfect setup.',
        },
      ],
    },
    {
      name: 'imageSrc',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue: 'https://tailwindui.com/img/ecommerce-images/product-feature-06-detail-01.jpg',
    },
    {
      name: 'imageAlt',
      type: 'string',
      defaultValue:
        'Maple organizer base with slots, supporting white polycarbonate trays of various sizes.',
    },
  ],
})
