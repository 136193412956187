import { Builder } from '@builder.io/react';
import { ProductFeaturesWithSquareImages } from './ProductFeaturesWithSquareImages';

Builder.registerComponent(ProductFeaturesWithSquareImages, {
  name: 'Product Features with square images',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd3fad4746b794e59a7bc6ba502ec4f44',
  inputs: [
    {
      name: 'sectionHeader',
      type: 'string',
      defaultValue: 'Focus',
    },
    {
      name: 'sectionTitle',
      type: 'string',
      defaultValue: 'Simple productivity',
    },
    {
      name: 'sectionDescription',
      type: 'string',
      defaultValue: `Focus allows you to plan 10 daily tasks, while also thinking ahead about what's next. Forget distracting digital apps and embrace these small, sturdy pieces of paper.`,
    },
    {
      name: "features",
      type: "list",
      subFields: [
        { name: "name", type: "string", defaultValue: "Three card types" },
        {
          name: "description", type: "string", defaultValue: `Today, Next, and Someday cards allow you to defer your dreams into the future.`
        }, {
          name: 'imageSrc',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
          defaultValue:
            'https://tailwindui.com/img/ecommerce-images/product-feature-08-detail-01.jpg',
        }, {
          name: "imageAlt", type: "string", defaultValue: `Green cardstock box containing white, beige, and brown cards.`
        }
      ],
    },

  ],
});
