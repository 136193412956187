import { restrictedRegister } from "blocks/utils"
import dynamic from "next/dynamic"

const isDemo = Boolean(process.env.IS_DEMO)
const LazyProductView = dynamic(
  () =>
    isDemo
      ? import(`blocks/product-sections/ProductViewWithImageGallery/ProductViewWithImageGallery`)
      : import(`blocks/product-sections/ProductViewWithImageGallery/ProductViewWithImageGallery`),
  { ssr: true }
)

restrictedRegister(
  LazyProductView,
  {
    name: "ProductViewWithImageGallery",
    image: "https://unpkg.com/css.gg@2.0.0/icons/svg/inpicture.svg",
    description:
      "Product details, should only be used in product page template, dynamically bind to product in context.",
    defaults: {
      bindings: {
        "component.options.product": "state.product",
        "component.options.swellProduct": "state.swellProduct",
        "component.options.title": "state.product.title",
        "component.options.description": "state.product.descriptionHtml",
      },
    },
  },
  ["product-page", "theme"]
)
