import React, { useState } from "react"
interface UntilInteractionProps {
  skeleton?: React.ReactNode
  children?: React.ReactNode
}

const UntilInteraction: React.FC<UntilInteractionProps> = ({ children, skeleton }) => {
  const [render, setRender] = useState(false)
  if (render) {
    return <>{children}</>
  }
  return (
    <div
      onMouseOver={() => setRender(true)}
      onClick={() => setRender(true)}
      onTouchStart={() => setRender(true)}
    >
      {skeleton}
    </div>
  )
}
export default UntilInteraction
