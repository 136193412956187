import { Builder } from '@builder.io/react'
import { ContactSectionsSplitTwoTone } from './ContactSectionsSplitTwoTone'

Builder.registerComponent(ContactSectionsSplitTwoTone, {
  name: 'Contact sections split two-tone',
  image: 'https://tailwindui.com/img/components/contact-sections.04-split-two-tone-xl.png',
  inputs: [
    {
      name: 'sectionTitle',
      type: 'string',
      defaultValue: 'Get in touch',
    },
    {
      name: 'sectionDescription',
      type: 'string',
      defaultValue:
        'Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.',
    },
    {
      name: 'businessAddress',
      type: 'string',
      defaultValue: '742 Evergreen Terrace',
    },
    {
      name: 'businessCity',
      type: 'string',
      defaultValue: 'Springfield',
    },
    {
      name: 'businessState',
      type: 'string',
      defaultValue: 'OR',
    },
    {
      name: 'businessZip',
      type: 'string',
      defaultValue: '12345',
    },
    {
      name: 'businessPhone',
      type: 'string',
      defaultValue: '+1 (555) 123-4567',
    },
    {
      name: 'businessEmail',
      type: 'string',
      defaultValue: 'support@example.com',
    },
    {
      name: 'ctaText',
      type: 'string',
      defaultValue: 'Looking for careers?',
    },
    {
      name: 'ctaButtonHref',
      type: 'string',
      defaultValue: '#',
    },
    {
      name: 'ctaButtonText',
      type: 'string',
      defaultValue: 'View all job openings',
    },
  ],
})
