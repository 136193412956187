import Image from "next/image"

export const ProductFeaturesWithTwoImages = (props) => {
  const { sectionTitle, sectionDescription, image1, image2, text1, text2 } = props

  return (
    <div className="bg-gray-50">
      <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        {/* Details section */}
        <section aria-labelledby="details-heading">
          {sectionTitle && (
            <div className="mb-16 flex flex-col items-center text-center">
              <h2
                id="details-heading"
                className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl"
              >
                {sectionTitle}
              </h2>
              <p className="mt-3 max-w-3xl text-lg text-gray-600">{sectionDescription}</p>
            </div>
          )}

          <div className="grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:gap-x-8">
            <div>
              <div className="aspect-h-2 aspect-w-3 w-full overflow-hidden rounded-lg">
                <Image
                  fill={true}
                  src={image1}
                  alt="Drawstring top with elastic loop closure and textured interior padding."
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <p className="mt-8 text-base text-gray-500">{text1}</p>
            </div>
            <div>
              <div className="aspect-h-2 aspect-w-3 w-full overflow-hidden rounded-lg">
                <Image
                  fill={true}
                  src={image2}
                  alt="Front zipper pouch with included key ring."
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <p className="mt-8 text-base text-gray-500">{text2}</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
