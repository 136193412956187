import { Builder } from '@builder.io/react'
import { PricingSectionsThreeTiers } from './PricingSectionsThreeTiers'

Builder.registerComponent(PricingSectionsThreeTiers, {
  name: 'Three tiers',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd3fad4746b794e59a7bc6ba502ec4f44',
  inputs: [
    {
      name: 'sectionHeader',
      type: 'string',
      defaultValue: 'Pricing',
    },
    {
      name: 'sectionDescription',
      type: 'string',
      defaultValue:
        "Choose an affordable plan that's packed with the best features for engaging your audience, creating customer loyalty, and driving sales.",
    },
    {
      name: 'tiers',
      type: 'list',
      subFields: [
        {
          name: 'title',
          type: 'string',
          defaultValue: 'Backwoods',
        },
        {
          name: 'price',
          type: 'string',
          defaultValue: '$30k',
        },
        {
          name: 'description',
          type: 'string',
          defaultValue: 'The essentials to provide your best work for clients.',
        },
        {
          name: 'features',
          type: 'list',
          subFields: [
            {
              name: 'name',
              type: 'string',
              defaultValue: '5 products',
            },
          ],
          defaultValue: [
            { name: '5 products' },
            { name: 'Up to 1,000 subscribers' },
            { name: 'Basic analytics' },
            { name: '48-hour support response time' },
          ],
        },
        {
          name: 'buttonText',
          type: 'string',
          defaultValue: 'Buy now',
        },
        {
          name: 'mostPopular',
          type: 'boolean',
          defaultValue: false,
        },
      ],
      defaultValue: [
        {
          title: 'Backwoods',
          price: '$40k',
          description: 'Backwoods package',
          features: [
            { name: '5 products' },
            { name: 'Up to 1,000 subscribers' },
            { name: 'Basic analytics' },
            { name: '48-hour support response time' },
          ],
          buttonText: 'Buy now',
          mostPopular: false,
        },
        {
          title: 'Evergreen',
          price: '$50k',
          description: 'Evergreen package',
          features: [
            { name: '5 products' },
            { name: 'Up to 1,000 subscribers' },
            { name: 'Basic analytics' },
            { name: '48-hour support response time' },
          ],
          buttonText: 'Buy now',
          mostPopular: true,
        },
        {
          title: 'Nomadic',
          price: '$60k',
          description: 'Nomadic package',
          features: [
            { name: '5 products' },
            { name: 'Up to 1,000 subscribers' },
            { name: 'Basic analytics' },
            { name: '48-hour support response time' },
          ],
          buttonText: 'Buy now',
          mostPopular: false,
        },
      ],
    },
  ],
})
