import React, { useEffect, useRef, useState } from "react"
import Hls from "hls.js"

export function MuxVideoPlayer(props) {
  const { playbackId } = props
  const gifStart = props.gifStart || "0"
  const gifEnd = props.gifEnd || "5"
  const videoRef = useRef(null)
  const src = `https://stream.mux.com/${playbackId}.m3u8`
  const imagePoster =
    props.posterImageSrc ||
    `https://image.mux.com/${playbackId}/thumbnail.jpg?${
      props.imageAt ? `time=${props.imageAt}` : ""
    }`

  const animatedPoster = `https://image.mux.com/${playbackId}/animated.gif?width=640&fps=5&start=${gifStart}&end=${gifEnd}`
  const [poster, setPoster] = useState(imagePoster)
  useEffect(() => {
    let hls
    if (videoRef.current) {
      const video = videoRef.current

      if (video.canPlayType("application/vnd.apple.mpegurl")) {
        // Some browers (safari and ie edge) support HLS natively
        video.src = src
      } else if (Hls.isSupported()) {
        // This will run in all other modern browsers
        hls = new Hls()
        hls.loadSource(src)
        hls.attachMedia(video)
      } else {
        console.error("This is a legacy browser that doesn't support MSE")
      }
    }

    return () => {
      if (hls) {
        hls.destroy()
      }
    }
  }, [videoRef])

  return (
    <div className="w-full">
      <video
        controls
        ref={videoRef}
        style={{ width: "100%", maxWidth: "100%" }}
        poster={poster}
        onMouseOver={() => setPoster(animatedPoster)}
        onMouseOut={() => setPoster(imagePoster)}
      />
    </div>
  )
}
