/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react"
import { CheckIcon, MinusIcon } from "@heroicons/react/20/solid"
import { classNames } from "core/utils"
import { getPrice } from "@lib/swell/storefront-data-hooks/src/utils/product"

export function PricingSectionsWithComparisonTable(props) {
  const { tiers, sections } = props
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl bg-white py-16 sm:py-24 sm:px-6 lg:px-8">
        {/* xs to lg */}
        <div className="mx-auto max-w-2xl space-y-16 lg:hidden">
          {tiers.map((tier, tierIdx) => (
            <section key={tier.name}>
              <div className="mb-8 px-4">
                <h2 className="text-lg font-medium leading-6 text-gray-900">{tier.name}</h2>
                <p className="mt-4">
                  <span className="text-4xl font-extrabold text-gray-900">{tier.price}</span>
                </p>
                <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                {tier.buttonText && (
                  <a
                    href={tier.href}
                    className="mt-6 block w-full rounded-md border border-gray-800 bg-gray-800 py-2 text-center text-sm font-semibold text-white hover:bg-gray-900"
                  >
                    {tier.buttonText}
                  </a>
                )}
              </div>

              {sections.map((section) => (
                <table key={section.name} className="w-full">
                  <caption className="border-t border-gray-200 bg-gray-50 py-3 px-4 text-left text-sm font-medium text-gray-900">
                    {section.name}
                  </caption>
                  <thead>
                    <tr>
                      <th className="sr-only" scope="col">
                        Feature
                      </th>
                      <th className="sr-only" scope="col">
                        Included
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {section.features.map((feature) => {
                      const featureTier = feature.tiers.find((t) => t.name === tier.name)
                      return (
                        <tr key={feature.name} className="border-t border-gray-200">
                          <th
                            className="py-5 px-4 text-left text-sm font-normal text-gray-500"
                            scope="row"
                          >
                            {feature.name}
                          </th>
                          <td className="py-5 pr-4">
                            {featureTier?.text ? (
                              <span className="block text-right text-sm text-gray-700">
                                {featureTier.text}
                              </span>
                            ) : (
                              <>
                                {featureTier ? (
                                  <CheckIcon
                                    className="ml-auto h-5 w-5 text-green-500"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <MinusIcon
                                    className="ml-auto h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                )}

                                <span className="sr-only">{featureTier ? "Yes" : "No"}</span>
                              </>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              ))}

              <div
                className={classNames(
                  tierIdx < tiers.length - 1 ? "border-b py-5" : "pt-5",
                  "border-t border-gray-200 px-4"
                )}
              >
                {tier.buttonText && (
                  <a
                    href={tier.href}
                    className="block w-full rounded-md border border-gray-800 bg-gray-800 py-2 text-center text-sm font-semibold text-white hover:bg-gray-900"
                  >
                    {tier.buttonText}
                  </a>
                )}
              </div>
            </section>
          ))}
        </div>

        {/* lg+ */}
        <div className="hidden lg:block">
          <table className="h-px w-full table-fixed">
            <caption className="sr-only">Pricing plan comparison</caption>
            <thead>
              <tr>
                <th className="px-6 pb-4 text-left text-sm font-medium text-gray-900" scope="col">
                  <span className="sr-only">Feature by</span>
                  <span>Plans</span>
                </th>
                {tiers.map((tier) => (
                  <th
                    key={tier.name}
                    className="w-1/4 px-6 pb-4 text-left text-lg font-medium leading-6 text-gray-900"
                    scope="col"
                  >
                    {tier.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 border-t border-gray-200">
              <tr>
                <th
                  className="py-8 px-6 text-left align-top text-sm font-medium text-gray-900"
                  scope="row"
                >
                  Pricing
                </th>
                {tiers.map((tier) => (
                  <td key={tier.name} className="h-full py-8 px-6 align-top">
                    <div className="relative table h-full">
                      <p>
                        <span className="text-4xl font-extrabold text-gray-900">{tier.price}</span>
                      </p>
                      <p className="mt-4 mb-16 text-sm text-gray-500">{tier.description}</p>
                      {tier.buttonText && (
                        <a
                          href={tier.href}
                          className="5 absolute bottom-0 block w-full flex-grow rounded-md border border-gray-800 bg-gray-800 py-2 text-center text-sm font-semibold text-white hover:bg-gray-900"
                        >
                          {tier.buttonText}
                        </a>
                      )}
                    </div>
                  </td>
                ))}
              </tr>
              {sections.map((section) => (
                <Fragment key={section.name}>
                  <tr>
                    <th
                      className="bg-gray-50 py-3 pl-6 text-left text-sm font-medium text-gray-900"
                      colSpan={4}
                      scope="colgroup"
                    >
                      {section.name}
                    </th>
                  </tr>
                  {section.features.map((feature) => (
                    <tr key={feature.name}>
                      <th
                        className="py-5 px-6 text-left text-sm font-normal text-gray-500"
                        scope="row"
                      >
                        {feature.name}
                      </th>
                      {tiers.map((tier) => {
                        const featureTier = feature.tiers.find((t) => t.name === tier.name)
                        return (
                          <td key={tier.name} className="py-5 px-6">
                            {featureTier?.text ? (
                              <span className="block text-sm text-gray-700">
                                {featureTier.text}
                              </span>
                            ) : (
                              <>
                                {featureTier ? (
                                  <CheckIcon
                                    className="h-5 w-5 text-green-500"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <MinusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                )}

                                <span className="sr-only">
                                  {featureTier ? "Included" : "Not included"} in {tier.name}
                                </span>
                              </>
                            )}
                          </td>
                        )
                      })}
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
            <tfoot>
              <tr className="border-t border-gray-200">
                <th className="sr-only" scope="row">
                  Choose your plan
                </th>
                {tiers.map((tier) => (
                  <td key={tier.name} className="px-6 pt-5">
                    {tier.buttonText && (
                      <a
                        href={tier.href}
                        className="block w-full rounded-md border border-gray-800 bg-gray-800 py-2 text-center text-sm font-semibold text-white hover:bg-gray-900"
                      >
                        {tier.buttonText}
                      </a>
                    )}
                  </td>
                ))}
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  )
}
