import { useState } from "react"
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline"
import {
  FoundersClubForm,
  FORM_ERROR,
  FORM_SUCCESS,
} from "@components/marketing-forms/FoundersClubForm"
import { SubscribeToFoundersClubForm } from "@lib/marketing-forms/validations"
import parsePhoneNumber from "libphonenumber-js"
import { ModalWithSingleAction } from "@components/ModalWithSingleAction"
import { useTrack } from "@components/track/context"

export function ContactSectionsSplitTwoTone(props: any) {
  const {
    sectionTitle,
    sectionDescription,
    businessAddress,
    businessCity,
    businessState,
    businessPhone,
    businessZip,
    businessEmail,
    ctaText,
    ctaButtonHref,
    ctaButtonText,
  } = props
  const [open, setOpen] = useState(false)
  const { setKlaviyoProfile } = useTrack()

  return (
    <div className="relative">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="mx-auto max-w-lg">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              {sectionTitle}
            </h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">{sectionDescription}</p>
            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="sr-only">Postal address</dt>
                <dd>
                  <p>{businessAddress}</p>
                  <p>{`${businessCity}, ${businessState} ${businessZip}`}</p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <PhoneIcon className="h-6 w-6 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">{businessPhone}</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <EnvelopeIcon
                    className="h-6 w-6 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">{businessEmail}</span>
                </dd>
              </div>
            </dl>
            <p className="mt-6 text-base text-gray-500">
              {ctaText}{" "}
              <a href={ctaButtonHref} className="font-medium text-gray-700 underline">
                {ctaButtonText}
              </a>
              .
            </p>
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="mx-auto max-w-lg lg:max-w-none">
            <ModalWithSingleAction
              title="Success"
              ctaText="Go back to page"
              description="Please check and verify your email. Thank you for subscribing."
              open={open}
              setOpen={setOpen}
            />

            <FoundersClubForm
              submitText="Subscribe"
              schema={SubscribeToFoundersClubForm}
              initialValues={{
                fullName: "",
                email: "",
                phone: "",
                message: "",
              }}
              onSubmit={async (values) => {
                try {
                  const body = JSON.stringify({
                    ...values,
                    phone: values.phone ? parsePhoneNumber(values.phone, "US")?.number : "",
                    listId: "Yg85C7",
                  })
                  const response = await fetch("/api/marketing-forms/subscribe-to-founders-club", {
                    method: "POST",
                    body,
                  }).then((r) => r.json())

                  if (!response.success) {
                    response.error?.name === "ZodError" && console.error("Errors:", response.error)
                    const message =
                      response.error?.name === "ZodError"
                        ? "Something went wrong on the server. Check the console for more info."
                        : response.error.toString()

                    throw new Error(message)
                  }

                  setOpen(true)
                  await setKlaviyoProfile({
                    email: values.email,
                    phone: values.phone,
                  })
                } catch (error: any) {
                  return {
                    [FORM_ERROR]: error.toString(),
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
