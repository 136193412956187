import { Builder } from '@builder.io/react'
import { SplitWithImage } from './SplitWithImage'
import { MuxVideoFields } from '@components/MuxVideoPlayer/MuxVideoPlayer.builder'

Builder.registerComponent(SplitWithImage, {
  name: 'split with image - stats sections',
  image: 'https://tailwindui.com/img/components/contact-sections.04-split-two-tone-xl.png',
  inputs: [
    {
      name: 'sectionTitle',
      type: 'string',
      defaultValue: 'Deliver what your customers want every time',
    },
    {
      name: 'sectionDescription',
      type: 'longText',
      defaultValue:
        'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolore nihil ea rerum ipsa. Nostrum consectetur sequi culpa doloribus omnis, molestiae esse placeat, exercitationem magnam quod molestias quia aspernatur deserunt voluptatibus.',
    },
    {
      name: 'muxVideo',
      type: 'object',
      subFields: MuxVideoFields,
      defaultValue: {
        playbackId: 'DWXhT68X56OFudQ7juvlK2t02rU6bR00eMDTVjhmGsIGQ',
        gifStart: '0',
        gifEnd: '5',
      },
    },
    {
      name: 'stats',
      type: 'list',
      subFields: [
        {
          name: 'value',
          type: 'string',
          defaultValue: '99%',
        },
        {
          name: 'name',
          stype: 'string',
          defaultValue: 'Satisfaction',
        },
      ],
      defaultValue: [
        {
          value: '99%',
          name: 'Satisfaction',
        },
        {
          value: '24/7',
          name: 'Support',
        },
        {
          value: 'yee',
          name: 'doggy',
        },
      ],
    },
  ],
})
