import { Builder } from '@builder.io/react';
import { ProductFeaturesWithTieredImages } from './ProductFeaturesWithTieredImages';

Builder.registerComponent(ProductFeaturesWithTieredImages, {
  name: 'Product Features with tiered images',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd3fad4746b794e59a7bc6ba502ec4f44',
  inputs: [
    {
      name: 'sectionHeader',
      type: 'string',
      defaultValue: 'Focus',
    },
    {
      name: 'sectionTitle',
      type: 'string',
      defaultValue: 'Simple productivity',
    },
    {
      name: "features",
      type: "list",
      subFields: [
        { name: "name", type: "string", defaultValue: "Sleek design" },
        {
          name: "description", type: "string", defaultValue: `The machined kettle has a smooth black finish and contemporary shape that stands apart from most plastic appliances.`
        }
      ],
    },
    {
      name: 'image1',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue:
        'https://tailwindui.com/img/ecommerce-images/product-feature-09-main-detail.jpg',
    },
    { name: 'image1Alt', type: 'string', required: true, defaultValue: "Black kettle with long pour spot and angled body on marble counter next to coffee mug and pour-over system." },
    {
      name: 'image2',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue:
        'https://tailwindui.com/img/ecommerce-images/product-feature-09-detail-01.jpg',
    },
    { name: 'image2Alt', type: 'string', required: true, defaultValue: "Detail of temperature setting button on kettle bass with digital degree readout." },

    {
      name: 'image3',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue:
        'https://tailwindui.com/img/ecommerce-images/product-feature-09-detail-02.jpg',
    },
    { name: 'image3Alt', type: 'string', required: true, defaultValue: "Kettle spout pouring boiling water into coffee grounds in pour-over mug." },

  ],
});
