import { useState } from "react"
import Link from "next/link"
import { VanCaveForm, FORM_ERROR, FORM_SUCCESS } from "@components/marketing-forms/VanCaveForm"
import { SubscribeToVanCaveForm } from "@lib/marketing-forms/validations"
import { ModalWithSingleAction } from "@components/ModalWithSingleAction"
import { useTrack } from "@components/track/context"

export function MarketingSectionsOptinParagraphOnDark(props: any) {
  const { sectionTitle, sectionDescription, buttonText, klaviyoListId } = props
  const [open, setOpen] = useState(false)

  const { setKlaviyoProfile, klaviyoProfile } = useTrack()

  return (
    <div className="bg-gray-800">
      <div className="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:flex lg:items-center lg:px-8 lg:py-16">
        <div className="lg:w-0 lg:flex-1">
          <h2
            className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl"
            id="newsletter-headline"
          >
            {sectionTitle}
          </h2>
          <p className="mt-3 max-w-3xl text-lg leading-6 text-gray-300">{sectionDescription}</p>
        </div>
        <div className="mt-8 lg:ml-8 lg:mt-0">
          <ModalWithSingleAction
            title="Success"
            ctaText="Go back to page"
            description="Please check and verify your email. Thank you for subscribing."
            open={open}
            setOpen={setOpen}
          />

          <VanCaveForm
            alternateSubmitText={buttonText}
            schema={SubscribeToVanCaveForm}
            initialValues={{
              email: klaviyoProfile.email || "",
            }}
            onSubmit={async (values) => {
              try {
                const body = JSON.stringify({
                  ...values,
                  listId: klaviyoListId,
                })
                const response = await fetch("api/marketing-forms/subscribe-to-van-cave", {
                  method: "POST",
                  body,
                }).then((r) => r.json())

                if (!response.success) {
                  response.error?.name === "ZodError" && console.error("Errors:", response.error)
                  const message =
                    response.error?.name === "ZodError"
                      ? "Something went wrong on the server. Check the console for more info."
                      : response.error.toString()

                  throw new Error(message)
                }
                setOpen(true)
                await setKlaviyoProfile(values)
              } catch (error: any) {
                return {
                  [FORM_ERROR]: error.toString(),
                }
              }
            }}
          />
          <p className="mt-3 text-sm text-gray-300">
            We care about the protection of your data. Read our{" "}
            <Link href="/privacy-policy" className="font-medium text-white underline">
              Privacy Policy.
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}
