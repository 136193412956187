import { Builder } from '@builder.io/react'
import { MuxVideoPlayer } from './MuxVideoPlayer'
export const MuxVideoFields = [
  {
    name: 'playbackId',
    type: 'string',
    defaultValue: 'DWXhT68X56OFudQ7juvlK2t02rU6bR00eMDTVjhmGsIGQ',
  },
  {
    name: 'imageAt',
    type: 'string',
  },
  {
    name: 'gifStart',
    type: 'string',
    defaultValue: '0',
  },
  {
    name: 'gifEnd',
    type: 'string',
    defaultValue: '5',
    helperText: 'Max gif is 10 seconds',
  },
  {
    name: 'posterImageSrc',
    type: 'file',
    allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
  },
]

Builder.registerComponent(MuxVideoPlayer, {
  name: 'Mux video player',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd3fad4746b794e59a7bc6ba502ec4f44',
  inputs: MuxVideoFields,
})
