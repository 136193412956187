import { Builder } from '@builder.io/react'
import { FooterSimpleCentered } from './FooterSimpleCentered'

Builder.registerComponent(FooterSimpleCentered, {
  name: 'Footer Simple Centered',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd3fad4746b794e59a7bc6ba502ec4f44',
  inputs: [
    {
      name: 'navigation',
      type: 'object',
      subFields: [
        {
          name: 'main',
          type: 'list',
          subFields: [
            { name: 'name', type: 'string', defaultValue: 'About' },
            { name: 'href', type: 'string', defaultValue: '#' },
          ],
        },
        {
          name: 'social',
          type: 'object',
          subFields: [
            {
              name: 'facebookLink',
              type: 'string',
              defaultValue: 'https://www.facebook.com/NewWayVanLife',
            },
            {
              name: 'instagramLink',
              type: 'string',
              defaultValue: 'https://www.instagram.com/newwayvanlife/',
            },
          ],
        },
      ],
    },
    {
      name: 'copyrightText',
      type: 'string',
      defaultValue: '&copy; 2021 New Way Van Life, Inc. All rights reserved.',
    },
  ],
})
