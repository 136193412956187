import { Fragment } from "react"
import { Tab } from "@headlessui/react"
import { BuilderBlocks } from "@builder.io/react"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export function ProductFeaturesWithTabs(props) {
  const { sectionTitle, sectionDescription, tabs, builderBlock, centered } = props
  return (
    <>
      <section aria-labelledby="features-heading" className="max-w-7xl sm:px-2 lg:px-8">
        <div className="max-w-2xl mx-auto px-4 lg:px-0 lg:max-w-none">
          {sectionTitle && (
            <div className="max-w-3xl mb-4">
              <h2
                id="features-heading"
                className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl"
              >
                {sectionTitle}
              </h2>
              <p className="mt-4 text-gray-500">{sectionDescription}</p>
            </div>
          )}

          <Tab.Group as="div" className="">
            <div className="-mx-4 flex overflow-x-auto sm:mx-0">
              <div className="flex-auto px-4 border-b border-gray-200 sm:px-0">
                <Tab.List
                  className={classNames(centered ? "justify-center" : "", "-mb-px flex space-x-10")}
                >
                  {tabs &&
                    tabs.map((tab, index) => (
                      <Tab
                        key={index}
                        className={({ selected }) =>
                          classNames(
                            selected
                              ? "border-primary-500 text-primary-600"
                              : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                            "whitespace-nowrap py-6 border-b-2 font-medium text-sm focus:outline-none"
                          )
                        }
                      >
                        {tab.name}
                      </Tab>
                    ))}
                </Tab.List>
              </div>
            </div>

            <Tab.Panels as={Fragment}>
              {tabs &&
                tabs.map((tab, index) => (
                  <Tab.Panel key={index} className="space-y-16 pt-10 lg:pt-16">
                    <BuilderBlocks parentElementId={builderBlock.id} blocks={tab.content} />
                  </Tab.Panel>
                ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </section>
    </>
  )
}
