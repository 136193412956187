import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/24/outline"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export function CenteredAccordionWithTitle(props) {
  const { sectionTitle, items } = props

  return (
    <div className="max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl divide-y-2 divide-gray-200">
        {sectionTitle && (
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
            {sectionTitle}
          </h2>
        )}
        <dl
          className={classNames(sectionTitle ? "mt-6" : "", "space-y-6 divide-y divide-gray-200")}
        >
          {items &&
            items.map((item) => (
              <Disclosure as="div" key={item.question} className="">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                        <span className="font-medium text-gray-900">{item.title}</span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-6 w-6 transform"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{item.text}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
        </dl>
      </div>
    </div>
  )
}
