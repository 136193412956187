import { Builder } from '@builder.io/react'
import { ProductFeaturesWithAlternatingImage } from './ProductFeaturesWithAlternatingImage'

Builder.registerComponent(ProductFeaturesWithAlternatingImage, {
  name: 'Product Features alternating image/description',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd3fad4746b794e59a7bc6ba502ec4f44',
  inputs: [
    {
      name: 'sectionTitle',
      type: 'string',
      defaultValue: 'Protect your device',
    },
    {
      name: 'sectionDescription',
      type: 'string',
      defaultValue:
        'As a digital creative, your laptop or tablet is at the center of your work. Keep your device safe with a fabric sleeve that matches in quality and looks.',
    },
    {
      name: 'features',
      type: 'list',
      subFields: [
        { name: 'name', type: 'string', defaultValue: 'Minimal and thoughtful' },
        {
          name: 'description',
          type: 'string',
          defaultValue: `Our laptop sleeve is compact and precisely fits 13" devices. The zipper allows you to access the interior with ease, and the front pouch provides a convenient place for your charger cable.`,
        },
        {
          name: 'imageSrc',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
          defaultValue:
            'https://tailwindui.com/img/ecommerce-images/product-feature-07-detail-01.jpg',
        },
        {
          name: 'imageAlt',
          type: 'string',
          defaultValue: `White canvas laptop sleeve with gray felt interior, silver zipper, and tan leather zipper pull.`,
        },
        {
          name: 'aspectWidth',
          type: 'string',
          enum: [
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
          ],
        },
        {
          name: 'aspectHeight',
          type: 'string',
          enum: [
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
          ],
        },
      ],
    },
  ],
})
