/* This example requires Tailwind CSS v2.0+ */
import { PhoneIcon } from "@heroicons/react/24/outline"
import Image from "next/image"

export function MarketingSectionsWithBackgroundImageAndOverlappingCards(props) {
  const { sectionTitle, sectionDescription, imageSrc, imageAlt, supportLinks, buttonText } = props

  return (
    <>
      {/* Header */}
      <div className="relative bg-gray-800 pb-32">
        <div className="absolute inset-0">
          <Image className="h-full w-full object-cover" src={imageSrc} alt={imageAlt} fill={true} />
          <div
            className="absolute inset-0 bg-gray-800 mix-blend-multiply"
            style={{ mixBlendMode: "multiply" }}
            aria-hidden="true"
          />
        </div>
        <div className="relative mx-auto max-w-7xl px-4 py-24 sm:px-6 sm:py-32 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">
            {sectionTitle}
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-gray-300">{sectionDescription}</p>
        </div>
      </div>

      {/* Overlapping cards */}
      <section
        className="relative z-10 mx-auto -mt-32 max-w-7xl px-4 pb-32 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <h2 className="sr-only" id="contact-heading">
          {buttonText}
        </h2>
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-0">
          {supportLinks &&
            supportLinks.map((link) => (
              <div key={link.name} className="flex flex-col rounded-2xl bg-white shadow-xl">
                <div className="relative flex-1 px-6 pb-8 pt-16 md:px-8">
                  <div className="absolute top-0 inline-block -translate-y-1/2 transform rounded-xl bg-primary-600 p-5 shadow-lg">
                    <PhoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <h3 className="text-xl font-medium text-gray-900">{link.name}</h3>
                  <p className="mt-4 text-base text-gray-500">{link.description}</p>
                </div>
                <div className="rounded-bl-2xl rounded-br-2xl bg-gray-50 p-6 md:px-8">
                  <a
                    href={link.href}
                    className="text-primary-700 hover:text-primary-600 text-base font-medium"
                  >
                    {buttonText}
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
                </div>
              </div>
            ))}
        </div>
      </section>
    </>
  )
}
