import { useContext, useState, useEffect } from "react"
import { Context } from "../Context"
import { Cart } from "../types"

export function useCart(): Cart | null {
  const { swell, cart, setCart } = useContext(Context)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/cart/get?cartId=${localStorage.getItem("cartId")}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })

        if (!response.ok) {
          throw new Error("Failed to fetch cart")
        }

        const result = await response.json()
        setCart(result)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  return cart
}
