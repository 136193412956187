import { Builder } from '@builder.io/react'
import { ProductFeaturesWithCenteredImage } from './ProductFeaturesWithCenteredImage'

Builder.registerComponent(ProductFeaturesWithCenteredImage, {
  name: 'Product Features w/ centered image',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd3fad4746b794e59a7bc6ba502ec4f44',
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Dynamic Design',
    },
    {
      name: 'description',
      type: 'string',
      defaultValue:
        'Whether you are full-time on the road or a weekend warrior we understand that everything in the van must serve multiple purposes simultaneously that is why optionality is the design and engineering foundation of New Way Van Life builds and products.',
    },
    {
      name: 'imageSrc',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue:
        'https://cdn.sanity.io/images/w9jjj9zj/production/b57e4d4dc1f63564cae7406979054f5e4ad01c9a-5519x3679.jpg',
    },
    {
      name: 'imageAlt',
      type: 'string',
      defaultValue:
        'Maple organizer base with slots, supporting white polycarbonate trays of various sizes.',
    },
  ],
})
