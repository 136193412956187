import Image from "next/image"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export function ProductFeaturesWithAlternatingImage(props) {
  const { sectionTitle, sectionDescription, features } = props
  return (
    <>
      <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        {sectionTitle && (
          <div className="mx-auto mb-16 max-w-3xl text-center">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              {sectionTitle}
            </h2>
            <p className="mt-4 text-gray-500">{sectionDescription}</p>
          </div>
        )}

        <div className="space-y-16">
          {features &&
            features.map((feature, featureIdx) => (
              <div
                key={featureIdx}
                className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center lg:gap-x-8"
              >
                <div
                  className={classNames(
                    featureIdx % 2 === 0 ? "lg:col-start-1" : "lg:col-start-8 xl:col-start-9",
                    "mt-6 lg:col-span-5 lg:row-start-1 lg:mt-0 xl:col-span-4"
                  )}
                >
                  <h3 className="text-lg font-medium text-gray-900">{feature.name}</h3>
                  <p className="mt-2 text-sm text-gray-500">{feature.description}</p>
                </div>
                <div
                  className={classNames(
                    featureIdx % 2 === 0 ? "lg:col-start-6 xl:col-start-5" : "lg:col-start-1",
                    "flex-auto lg:col-span-7 lg:row-start-1 xl:col-span-8"
                  )}
                >
                  <div
                    className={`aspect-w-${feature.aspectWidth} aspect-h-${feature.aspectHeight} overflow-hidden rounded-lg bg-gray-100`}
                  >
                    <Image
                      src={feature.imageSrc}
                      alt={feature.imageAlt}
                      className="object-cover object-center"
                      fill={true}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  )
}
