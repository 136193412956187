import "@assets/main.css"
import "keen-slider/keen-slider.min.css"
import React, { FC, StrictMode, useEffect } from "react"
import type { AppProps } from "next/app"
import { useRouter } from "next/router"
import Script from "next/script"
import builderConfig from "@config/builder"
import { builder, Builder } from "@lib/register-custom-components"
builder.init(builderConfig.apiKey)
import * as fbq from "../lib/fpixel"

import "../blocks/ProductGrid/ProductGrid.builder"
import "../blocks/CollectionView/CollectionView.builder"
import "../blocks/ProductCardsWithDetails/ProductCardsWithDetails.builder"
import "../blocks/ProductCardsWithTallImage/ProductCardsWithTallImage.builder"
import "../blocks/ProductsListWithHorizontalScrolling/ProductsListWithHorizontalScrolling.builder"
import "../blocks/ProductView/ProductView.builder"
import "../blocks/product-sections/ProductViewWithGrid/ProductViewWithGrid.builder"
import "../blocks/product-sections/ProductViewWithImageGallery/ProductViewWithImageGallery.builder"
import "../blocks/product-sections/ProductViewWithTabs/ProductViewWithTabs.builder"

Builder.register("insertMenu", {
  name: "Swell Collection Components",
  items: [
    { name: "CollectionBox", label: "Collection" },
    { name: "CollectionView" },
    { name: "CollectionCardsWithDetails" },
    { name: "CollectionCardsWithTallImage" },
  ],
})

Builder.register("insertMenu", {
  name: "Swell Products Components",
  items: [
    { name: "ProductBox" },
    { name: "ProductGrid" },
    { name: "ProductCardsWithDetails" },
    { name: "ProductCardsWithTallImage" },
    { name: "ProductsListWithHorizontalScrolling" },
    { name: "ProductCollectionGrid" },
  ],
})

Builder.register("insertMenu", {
  name: "Swell Product Page components",
  items: [
    { name: "ProductView" },
    { name: "ProductViewWithGrid" },
    { name: "ProductViewWithImageGallery" },
    { name: "ProductViewWithTabs" },
  ],
})

Builder.register("insertMenu", {
  name: "Swell Configurator Page components",
  items: [{ name: "ConfiguratorView" }],
})

// log the pageview with their URL
export const pageview = (url: string) => {
  // @ts-ignore
  window.gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
    page_path: url,
  })
}

// log specific events happening.
export const event = ({ action, params }: { action: any; params: any }) => {
  // @ts-ignore
  window.gtag("event", action, params)
}

const Noop: FC<{ children: React.ReactNode }> = ({ children }) => <>{children}</>

export default function MyApp({ Component, pageProps }: AppProps) {
  const Layout = (Component as any).Layout || Noop
  const router = useRouter()
  useEffect(() => {
    fbq.pageview()

    const handleRouteChange = (url: string) => {
      pageview(url)
      fbq.pageview()
    }
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on("routeChangeComplete", handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events])

  return (
    <StrictMode>
      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
        }}
      />

      <Layout pageProps={pageProps}>
        <Component {...pageProps} />
      </Layout>
    </StrictMode>
  )
}
