import { classNames } from "core/utils"
import Image from "next/legacy/image"

export function EyeCandy(props) {
  const { imageSrc, children, aspectWidth, aspectHeight, overlayClasses } = props
  return (
    <div
      className={classNames(
        aspectHeight ? `aspect-h-${aspectHeight}` : "",
        aspectWidth ? `aspect-w-${aspectWidth}` : "",
        "relative"
      )}
    >
      <div className="absolute inset-0">
        <Image
          className="h-full w-full object-cover"
          src={imageSrc}
          alt="hero"
          fill={true}
          objectFit="cover"
        />
        <div className={classNames(overlayClasses ? overlayClasses : "", "absolute inset-0")} />
      </div>

      <div className="absolute inset-0 mx-auto flex h-full px-4 sm:px-6 lg:px-8 ">{children}</div>
    </div>
  )
}
