import Image from "next/legacy/image"

export function ProductFeaturesWithTieredImages(props) {
  const {
    sectionHeader,
    sectionTitle,
    features,
    image1,
    image2,
    image3,
    image1Alt,
    image2Alt,
    image3Alt,
  } = props
  return (
    <>
      <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
          <div>
            <div className="border-b border-gray-200 pb-10">
              <h2 className="font-medium text-gray-500">{sectionHeader}</h2>
              <p className="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {sectionTitle}
              </p>
            </div>

            <dl className="mt-10 space-y-10">
              {features &&
                features.map((feature) => (
                  <div key={feature.name}>
                    <dt className="text-sm font-medium text-gray-900">{feature.name}</dt>
                    <dd className="mt-3 text-sm text-gray-500">{feature.description}</dd>
                  </div>
                ))}
            </dl>
          </div>

          <div>
            <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100">
              <Image src={image1} alt={image1Alt} fill={true} objectFit="cover" />
            </div>
            <div className="mt-4 grid grid-cols-2 gap-4 sm:mt-6 sm:gap-6 lg:mt-8 lg:gap-8">
              <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100">
                <Image src={image2} alt={image2Alt} fill={true} objectFit="cover" />
              </div>
              <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100">
                <Image src={image3} alt={image3Alt} fill={true} objectFit="cover" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
