import { Builder } from '@builder.io/react'
import { IncentiveFourColumnWithIllustrations } from './IncentiveFourColumnWithIllustrations'

Builder.registerComponent(IncentiveFourColumnWithIllustrations, {
  name: '4-column with illustrations',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd3fad4746b794e59a7bc6ba502ec4f44',
  inputs: [
    {
      name: 'incentives',
      type: 'list',
      subFields: [
        { name: 'name', type: 'string', defaultValue: 'Free Shipping' },
        {
          name: 'description',
          type: 'string',
          defaultValue:
            "It's not actually free we just price it into the products. Someone's paying for it, and it's not us.",
        },
        {
          name: 'imageSrc',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          required: true,
          defaultValue: 'https://tailwindui.com/img/ecommerce/icons/icon-delivery-light.svg',
        },
      ],
    },
  ],
})
