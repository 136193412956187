import { Builder } from '@builder.io/react'
import { ThreeColumnBlogArticles } from './ThreeColumnBlogArticles'

Builder.registerComponent(ThreeColumnBlogArticles, {
  name: 'Three column blog layout',
  inputs: [
    {
      name: 'sectionTitle',
      type: 'string',
      defaultValue: 'From the blog',
    },
    {
      name: 'description',
      type: 'string',
      defaultValue:
        'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa libero labore natus atque, ducimus sed.',
    },
  ],
})
