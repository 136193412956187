import { Builder } from '@builder.io/react'
import { WithImageAndShortParagraph } from './WithImageAndShortParagraph'

Builder.registerComponent(WithImageAndShortParagraph, {
  name: 'with image and short paragraph - team sections',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd3fad4746b794e59a7bc6ba502ec4f44',
  inputs: [
    {
      name: 'sectionTitle',
      type: 'string',
      defaultValue: 'Our Team',
    },
    {
      name: 'sectionDescription',
      type: 'longText',
      defaultValue:
        'Nulla quam felis, enim faucibus proin velit, ornare id pretium. Augue ultrices sed arcu condimentum vestibulum suspendisse. Volutpat eu faucibus vivamus eget bibendum cras.',
    },
    {
      name: 'people',
      type: 'list',
      subFields: [
        {
          name: 'name',
          type: 'string',
          defaultValue: 'Emma Dorsey',
        },
        {
          name: 'role',
          type: 'string',
          defaultValue: 'Senior Front-end Developer',
        },
        {
          name: 'imageUrl',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
          defaultValue:
            'https://images.unsplash.com/photo-1505840717430-882ce147ef2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
        },
        {
          name: 'bio',
          type: 'longText',
          defaultValue:
            'Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.',
        },
        {
          name: 'twitterUrl',
          type: 'string',
          defaultValue: '#',
        },
        {
          name: 'linkedinUrl',
          type: 'string',
          defaultValue: '#',
        },
        {
          name: 'instagramUrl',
          type: 'string',
          defaultValue: '#',
        },
      ],
      defaultValue: [
        {
          name: 'Emma Dorsey',
          role: 'Senior Front-end Developer',
          imageUrl:
            'https://images.unsplash.com/photo-1505840717430-882ce147ef2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
          bio:
            'Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.',
        },
      ],
    },
  ],
})
