import Image from "next/image"

export function ProductFeaturesWithCenteredImage(props) {
  const { title, description, imageSrc, imageAlt } = props
  return (
    <div className="flex flex-col items-center">
      <div className="sm:flex sm:flex-col sm:items-center lg:mt-0">
        <div className="sm:w-2/3">
          <div className="aspect-h-1 aspect-w-2 overflow-hidden rounded-lg bg-gray-100 sm:aspect-h-3 sm:aspect-w-5">
            <Image
              src={imageSrc}
              alt={imageAlt}
              className="object-cover object-center"
              fill={true}
            />
          </div>
        </div>

        <div className="mt-6">
          <h3 className="text-center text-lg font-medium text-gray-900">{title}</h3>
          <p className="mt-2 text-center text-sm text-gray-500">{description}</p>
        </div>
      </div>
    </div>
  )
}
