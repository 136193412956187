import React, { FC, useEffect } from "react"
import Cookies from "js-cookie"
import KlaviyoClient from "@integrations/klaviyo"

export interface State {
  klaviyoProfile: { email?: string; first_name?: string; last_name?: string; phone?: string }
  setKlaviyoProfile: (klaviyoProfile: State["klaviyoProfile"]) => void
}

const initialState = {
  klaviyoProfile: {},
  setKlaviyoProfile: (klaviyoProfile: State["klaviyoProfile"]) => {},
}

export const TrackContext = React.createContext<State>(initialState)

TrackContext.displayName = "NwvlCookie"

const COOKIE_NAME = "nwvl-cookie"

const getStateFromCookie = () => {
  const cookieData = Cookies.get(COOKIE_NAME)
  if (!cookieData) return {}
  return JSON.parse(cookieData)
}

const setStateCookie = (state: State) => {
  Cookies.set(COOKIE_NAME, JSON.stringify(state), { expires: 365 })
}
function JSON_to_URLEncoded(element: any, key: any, list: any) {
  var list = list || []
  if (typeof element == "object") {
    for (var idx in element)
      JSON_to_URLEncoded(element[idx], key ? key + "[" + idx + "]" : idx, list)
  } else {
    list.push(key + "=" + encodeURIComponent(element))
  }
  return list.join("&")
}

export const TrackProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const setKlaviyoProfile = async (klaviyoProfile: State["klaviyoProfile"]) => {
    setState({ ...state, klaviyoProfile })
    setStateCookie({ ...state, klaviyoProfile })
    const klaviyoProfileProperties = {}
    for (var key in klaviyoProfile) {
      if (klaviyoProfile.hasOwnProperty(key)) {
        // @ts-ignore
        klaviyoProfileProperties[`$${key}`] = klaviyoProfile[key]
      }
    }
    const response = await KlaviyoClient.public.identify({
      email: klaviyoProfile.email,
      properties: klaviyoProfileProperties,
      post: true, //defaults to false
    })

    try {
      const profileResponse = await fetch("api/klaviyo/getProfile", {
        method: "POST",
        body: JSON.stringify({ email: klaviyoProfile.email }),
      }).then((r) => r.json())
    } catch (e) {
      console.error(e)
    }

    // TODO call identify api route
  }

  const [state, setState] = React.useState({
    ...initialState,
    ...getStateFromCookie,
    setKlaviyoProfile,
  })

  const value = {
    ...state,
  }

  return <TrackContext.Provider value={value}>{children}</TrackContext.Provider>
}

export const useTrack = () => {
  const context = React.useContext(TrackContext)
  if (context === undefined) {
    throw new Error(`useTrack must be used within a TrackProvider`)
  }
  return context
}

export const ManagedTrackContext: FC<{ children: React.ReactNode }> = ({ children }) => (
  <TrackProvider>{children}</TrackProvider>
)
