import { forwardRef, PropsWithoutRef } from "react"
import { useField, useFormikContext, ErrorMessage } from "formik"
import { classNames } from "core/utils"

export interface LabeledTextAreaFieldProps
  extends PropsWithoutRef<JSX.IntrinsicElements["textarea"]> {
  /** Field name. */
  name: string
  /** Field label. */
  label: string
  /** Field hidden */
  hiddenLabel?: boolean
  outerProps?: PropsWithoutRef<JSX.IntrinsicElements["div"]>
}

export const LabeledTextAreaField = forwardRef<HTMLTextAreaElement, LabeledTextAreaFieldProps>(
  ({ name, label, hiddenLabel, outerProps, ...props }, ref) => {
    const [input] = useField(name)
    const { isSubmitting } = useFormikContext()

    return (
      <div {...outerProps}>
        <label htmlFor={name} className={classNames(hiddenLabel ? "sr-only" : "")}>
          {label}
        </label>
        <textarea
          {...input}
          disabled={isSubmitting}
          {...props}
          ref={ref}
          rows={4}
          className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-primary-500 focus:border-primary-500 border border-gray-300 rounded-md"
        />

        <ErrorMessage
          name={name}
          render={(msg) => (
            <div role="alert" className="text-red-500">
              {msg}
            </div>
          )}
        />
      </div>
    )
  }
)

export default LabeledTextAreaField
