import { Builder } from '@builder.io/react'
import { MarketingSectionsOptinParagraphOnDark } from './MarketingSectionsOptinParagraphOnDark'

Builder.registerComponent(MarketingSectionsOptinParagraphOnDark, {
  name: 'Marketing sections opt in paragraph on dark',
  image:
    'https://tailwindui.com/img/components/newsletter-sections.03-with-paragraph-on-dark-xl.png',
  inputs: [
    {
      name: 'sectionTitle',
      type: 'string',
      defaultValue: 'Sign up for our newsletter',
    },
    {
      name: 'sectionDescription',
      type: 'string',
      defaultValue:
        'Organize is a Varius facilisi mauris sed sit. Non sed et duis dui leo, vulputate id malesuada non. Cras aliquet purus dui laoreet diam sed lacus, fames. Dui, amet, nec sit pulvinar. system to keep your desk tidy and photo- worthy all day long.Procrastinate your work while you meticulously arrange items into dedicated trays.',
    },
    {
      name: 'buttonText',
      type: 'string',
      defaultValue: 'Notify me',
    },
    {
      name: 'klaviyoListId',
      type: 'string',
      defaultValue: 'Ujm6sD',
    },
  ],
})
