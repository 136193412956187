import { Builder } from '@builder.io/react'
import { PromoWithFadingImageAndTestimonials } from './PromoWithFadingImageAndTestimonials'

Builder.registerComponent(PromoWithFadingImageAndTestimonials, {
  name: 'With fading background image and testimonials',
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd3fad4746b794e59a7bc6ba502ec4f44',
  inputs: [
    {
      name: 'sectionTitle',
      type: 'string',
      defaultValue: 'Get 25% off during our one-time sale',
    },
    {
      name: 'sectionDescription',
      type: 'string',
      defaultValue: `Most of our products are limited releases that won't come back. Get your favorite items while they're in stock.`,
    },
    {
      name: 'backgroundImageSrc',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
      required: true,
      defaultValue: 'https://tailwindui.com/img/ecommerce-images/home-page-02-sale-full-width.jpg',
    },
    {
      name: 'testimonials',
      type: 'list',
      subFields: [
        {
          name: 'quote',
          type: 'string',
          defaultValue:
            'My order arrived super quickly. The product is even better than I hoped it would be. Very happy customer over here!',
        },
        { name: 'attribution', type: 'string', defaultValue: 'Sarah Peters, New Orleans' },
      ],
    },
  ],
})
